import { ReactElement } from 'react'

import CircularProgress from '@mui/material/CircularProgress'

type Props = {
  message?: string | undefined;
}

export default function Loader({ message = undefined }: Props): ReactElement {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center h-100 w-100">
      <CircularProgress />
      {message && <div className="m-2">{`${message}...`}</div>}
    </div>
  )
}
