import { ReactElement, ReactNode } from 'react'

import { PORTAL_URL } from 'config/config'
import history from 'customHistory'

import GaiavizLogo from 'assets/logos/gaiaviz-logo.png'
import logo from 'assets/logos/logo-DGEXSOL-full-white.svg'

import { isGaiavizApp } from 'components/utils'
import { MAIN_CONFIG } from 'config'
import AccountMenu from './AccountMenu'
import './TopBar.scss'

type Props = {
  appName: string;
  innerComponent?: ReactNode;
  hasAccess: boolean;
}

export default function TopBar({ appName, innerComponent = <div />, hasAccess }: Props): ReactElement {
  return (
    <div className="topbar d-flex align-items-center justify-content-between px-3">
      <header role="banner" className="d-none d-xl-flex">
        <div className="mastheader-logo">
          <a href={PORTAL_URL.url}><img alt={appName} src={logo} width="70" /></a>
          {isGaiavizApp()
            && <img alt={appName} src={GaiavizLogo} className="ml-4" />}
        </div>
        <div
          className="d-flex align-items-center"
          aria-hidden
          role="button"
          onClick={() => history.replace('/')}
        >
          {!isGaiavizApp()
          && <h1 className="text-uppercase pl-3 pt-1 mb-0 app-name">{MAIN_CONFIG.app_name.toUpperCase()}</h1> }
        </div>
      </header>
      <div className="menu">
        {hasAccess && innerComponent}
        <AccountMenu />
      </div>
    </div>
  )
}
